import React from "react";

import "./style.css";
import lock from "../../assets/img/lock.png";
import Reveal from "../Reveal";
function Banner() {
  return (
    <div className="banner_wrapper">
      <div className="banner_container">
        <div className="banner_sides">
          <div className="left_side">
            <Reveal
              component={
                <h1>
                  Melhore a <strong>segurança</strong> dos <br />
                  seus e-mails com o MAV Mail
                </h1>
              }
              delay={0.7}
            />
            <Reveal
              component={
                <p>
                  A segurança do ambiente de e-mails é crucial para proteger
                  informações sensíveis e evitar ataques. Ignorar esta questão
                  pode resultar em diversos problemas, incluindo:
                </p>
              }
              delay={1}
            />
          </div>

          <div className="right_side">
            <Reveal component={<img src={lock} alt="lock"></img>}delay={1.2} />
          </div>
        </div>

        <div className="banner_options_container">
          <Reveal
            component={
              <div className="option_block">
                <div className="box">
                  <svg
                    width="28"
                    height="33"
                    viewBox="0 0 28 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 9.28125V12.375H19V9.28125C19 6.43242 16.7625 4.125 14 4.125C11.2375 4.125 9 6.43242 9 9.28125ZM5 12.375V9.28125C5 4.15723 9.03125 0 14 0C18.9688 0 23 4.15723 23 9.28125V12.375H24C26.2062 12.375 28 14.2248 28 16.5V28.875C28 31.1502 26.2062 33 24 33H4C1.79375 33 0 31.1502 0 28.875V16.5C0 14.2248 1.79375 12.375 4 12.375H5Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3>Boleto alterado no recebimento:</h3>
                <p>
                  Possibilidade de substituição de boletos autênticos por
                  falsificados.
                </p>
              </div>
            }
            delay={1.4}
          />

          <Reveal
            component={
              <div className="option_block">
                <div className="box">
                  <svg
                    width="28"
                    height="33"
                    viewBox="0 0 28 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 9.28125V12.375H19V9.28125C19 6.43242 16.7625 4.125 14 4.125C11.2375 4.125 9 6.43242 9 9.28125ZM5 12.375V9.28125C5 4.15723 9.03125 0 14 0C18.9688 0 23 4.15723 23 9.28125V12.375H24C26.2062 12.375 28 14.2248 28 16.5V28.875C28 31.1502 26.2062 33 24 33H4C1.79375 33 0 31.1502 0 28.875V16.5C0 14.2248 1.79375 12.375 4 12.375H5Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3>Novos envios de e-mails:</h3>
                <p>
                  Atacantes utilizam informações de e-mails autênticos para
                  enviar phishing ou boletos falsos.
                </p>
              </div>
            }
            delay={1.6}
          />
          <Reveal
            component={
              <div className="option_block">
                <div className="box">
                  <svg
                    width="28"
                    height="33"
                    viewBox="0 0 28 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 9.28125V12.375H19V9.28125C19 6.43242 16.7625 4.125 14 4.125C11.2375 4.125 9 6.43242 9 9.28125ZM5 12.375V9.28125C5 4.15723 9.03125 0 14 0C18.9688 0 23 4.15723 23 9.28125V12.375H24C26.2062 12.375 28 14.2248 28 16.5V28.875C28 31.1502 26.2062 33 24 33H4C1.79375 33 0 31.1502 0 28.875V16.5C0 14.2248 1.79375 12.375 4 12.375H5Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3>Envio de SPAM e Fraude:</h3>
                <p>
                  Contas comprometidas podem ser utilizadas para envio de SPAM,
                  prejudicando a confiança do domínio
                </p>
              </div>
            }
            delay={1.8}
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
