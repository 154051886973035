import pass from '../assets/img/pass.svg'
import noti from '../assets/img/notification.svg'
import globe from '../assets/img/globe.svg'
import security from '../assets/img/security.svg'
import block from '../assets/img/blocke.svg'
import expire from '../assets/img/expire.svg'
import vazamento from '../assets/img/vazamento.svg'

export const data = [
  {
    id: 0,
    title: "Política de senha robusta",
    sub: "Defina senhas seguras para evitar comprometimento de conta. O MAV Mail oferece:",
    topicos: [
      { topic: "•	Tamanho mínimo de senha de 6 caracteres." },
      { topic: "•	Exigência de letras secretas e minúsculas." },
      { topic: "•	Necessidade de incluir números e caracteres especiais." },
      {
        topic:
          "•	Restrições adicionais como bloqueio de senhas sequenciais e similares ao e-mail ou domínio.",
      },
    ],
    img:pass,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_9_173)">
          <path
            d="M21 22C27.075 22 32 17.075 32 11C32 4.925 27.075 0 21 0C14.925 0 10 4.925 10 11C10 12.1687 10.1813 13.3 10.5188 14.3562L0.4375 24.4375C0.15625 24.7188 0 25.1 0 25.5V30.5C0 31.3312 0.66875 32 1.5 32H6.5C7.33125 32 8 31.3312 8 30.5V28H10.5C11.3313 28 12 27.3312 12 26.5V24H14.5C14.9 24 15.2812 23.8438 15.5625 23.5625L17.6437 21.4813C18.7 21.8188 19.8312 22 21 22ZM23.5 6C24.163 6 24.7989 6.26339 25.2678 6.73223C25.7366 7.20107 26 7.83696 26 8.5C26 9.16304 25.7366 9.79893 25.2678 10.2678C24.7989 10.7366 24.163 11 23.5 11C22.837 11 22.2011 10.7366 21.7322 10.2678C21.2634 9.79893 21 9.16304 21 8.5C21 7.83696 21.2634 7.20107 21.7322 6.73223C22.2011 6.26339 22.837 6 23.5 6Z"
            fill=""
          />
        </g>
        <defs>
          <clipPath id="clip0_9_173">
            <rect width="32" height="32" fill="" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 1,
    title: "Vazamento de senha",
    sub: "Verifique se as senhas foram comprometidas em sites externos e como troque para garantir a segurança",
    img:vazamento,
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_9_178)">
          <path
            d="M16 1.15789C15.5214 1.15789 15.2286 0.955263 14.8929 0.716447C14.4214 0.390789 13.8571 0 12.5714 0C10.3929 0 8.85714 3.1625 7.85714 6.46974C4.47857 7.09934 2.28571 8.11974 2.28571 9.26316C2.28571 10.298 4.07143 11.2243 6.9 11.8612C6.87143 12.1507 6.85714 12.4401 6.85714 12.7368C6.85714 13.9671 7.09286 15.1395 7.52143 16.2105H3.24286C2.71429 16.2105 2.28571 16.6447 2.28571 17.1803C2.28571 17.3033 2.30714 17.4263 2.35714 17.5421L5.12857 24.5546C2.01429 26.9066 0 30.6697 0 34.9033C0 36.0901 0.95 37.0526 2.12143 37.0526H29.8786C31.05 37.0526 32 36.0901 32 34.9033C32 30.6697 29.9857 26.9138 26.8786 24.5546L29.6429 17.5421C29.6857 17.4263 29.7143 17.3033 29.7143 17.1803C29.7143 16.6447 29.2857 16.2105 28.7571 16.2105H24.4786C24.9071 15.1395 25.1429 13.9671 25.1429 12.7368C25.1429 12.4401 25.1286 12.1507 25.1 11.8612C27.9286 11.2243 29.7143 10.298 29.7143 9.26316C29.7143 8.11974 27.5214 7.09934 24.1429 6.46974C23.1429 3.1625 21.6071 0 19.4286 0C18.1429 0 17.5786 0.390789 17.1071 0.716447C16.7643 0.955263 16.4786 1.15789 16 1.15789ZM20 16.2105H19.1143C17.9357 16.2105 16.8929 15.4434 16.5214 14.3145C16.3571 13.8079 15.65 13.8079 15.4857 14.3145C15.1143 15.4434 14.0643 16.2105 12.8929 16.2105H12C10.4214 16.2105 9.14286 14.9151 9.14286 13.3158V12.2737C11.1571 12.5704 13.5 12.7368 16 12.7368C18.5 12.7368 20.8429 12.5704 22.8571 12.2737V13.3158C22.8571 14.9151 21.5786 16.2105 20 16.2105ZM13.7143 23.1579L14.8571 25.4737L12.5714 34.7368L9.14286 20.8421L13.7143 23.1579ZM22.8571 20.8421L19.4286 34.7368L17.1429 25.4737L18.2857 23.1579L22.8571 20.8421Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_178">
            <rect width="32" height="37.0526" fill="" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Notificação de login",
    img:noti,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_9_183)">
          <path
            d="M16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0ZM16 24C16.8312 24 17.5 23.3313 17.5 22.5V15.5C17.5 14.6688 16.8312 14 16 14C15.1687 14 14.5 14.6688 14.5 15.5V22.5C14.5 23.3313 15.1687 24 16 24ZM14 10C14 10.5304 14.2107 11.0391 14.5858 11.4142C14.9609 11.7893 15.4696 12 16 12C16.5304 12 17.0391 11.7893 17.4142 11.4142C17.7893 11.0391 18 10.5304 18 10C18 9.46957 17.7893 8.96086 17.4142 8.58579C17.0391 8.21071 16.5304 8 16 8C15.4696 8 14.9609 8.21071 14.5858 8.58579C14.2107 8.96086 14 9.46957 14 10Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_183">
            <rect
              width="32"
              height="32"
              fill=""
              transform="matrix(1 0 0 -1 0 32)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    sub: "Receba alertas sobre logins em locais não usuais para identificar possíveis acessos não autorizados.",
  },
  {
    id: 3,
    title: "Bloqueio de envio internacional",
    img:globe,
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_9_186)">
          <path
            d="M16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0ZM10.9375 21.0625C11.525 21.65 12.475 21.65 13.0562 21.0625L15.9937 18.125L18.9312 21.0625C19.5187 21.65 20.4688 21.65 21.05 21.0625C21.6312 20.475 21.6375 19.525 21.05 18.9438L18.1125 16.0063L21.05 13.0688C21.6375 12.4813 21.6375 11.5312 21.05 10.95C20.4625 10.3688 19.5125 10.3625 18.9312 10.95L15.9937 13.8875L13.0562 10.95C12.4688 10.3625 11.5188 10.3625 10.9375 10.95C10.3562 11.5375 10.35 12.4875 10.9375 13.0688L13.875 16.0063L10.9375 18.9438C10.35 19.5312 10.35 20.4812 10.9375 21.0625Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_186">
            <rect
              width="32"
              height="32"
              fill=""
              transform="matrix(1 0 0 -1 0 32)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    sub: "Aumente a segurança configurando o envio de e-mails apenas a partir de IPs no Brasil, diminuindo o risco de comprometimento.",
  },

  {
    id: 4,
    title: "Zona de segurança",
    img:security,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g clip-path="url(#clip0_9_191)">
          <path
            d="M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM23.0625 13.0625L15.0625 21.0625C14.475 21.65 13.525 21.65 12.9438 21.0625L8.94375 17.0625C8.35625 16.475 8.35625 15.525 8.94375 14.9438C9.53125 14.3625 10.4812 14.3563 11.0625 14.9438L14 17.8813L20.9375 10.9375C21.525 10.35 22.475 10.35 23.0562 10.9375C23.6375 11.525 23.6437 12.475 23.0562 13.0562L23.0625 13.0625Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_191">
            <rect width="32" height="32" fill="" />
          </clipPath>
        </defs>
      </svg>
    ),
    sub: "Configure o envio e o acesso de e-mails exclusivamente no ambiente empresarial para evitar acessos não seguros.",
  },

  {
    id: 5,
    title: "Expiração de senha",
    img:expire,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g clip-path="url(#clip0_9_194)">
          <path
            d="M28.6938 3.275L27.6625 0.40625C27.5438 0.1625 27.2812 0 27.0063 0C26.7313 0 26.475 0.1625 26.3563 0.40625L25.325 3.275L22.45 4.325C22.1813 4.425 21.9937 4.69375 22 4.975C22 5.25625 22.1875 5.51875 22.45 5.6125L25.3062 6.6625L26.3563 9.525C26.45 9.8 26.7188 9.99375 27.0063 9.99375C27.2938 9.99375 27.5625 9.8 27.6562 9.525L28.6875 6.6625L31.5438 5.6125C31.8063 5.51875 31.9937 5.25625 31.9937 4.975C31.9937 4.6875 31.8063 4.41875 31.5438 4.325L28.6938 3.275ZM20.4188 6.5875C19.6375 5.80625 18.3687 5.80625 17.5875 6.5875L17.4062 6.76875C16.0312 6.26875 14.5437 6 13 6C5.81875 6 0 11.8188 0 19C0 26.1812 5.81875 32 13 32C20.1812 32 26 26.1812 26 19C26 17.4562 25.7312 15.9688 25.2375 14.5938L25.4188 14.4125C26.2 13.6313 26.2 12.3625 25.4188 11.5813L20.4188 6.58125V6.5875ZM12.5 12C8.9125 12 6 14.9125 6 18.5V19C6 19.55 5.55 20 5 20C4.45 20 4 19.55 4 19V18.5C4 13.8062 7.80625 10 12.5 10H13C13.55 10 14 10.45 14 11C14 11.55 13.55 12 13 12H12.5Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_194">
            <rect width="32" height="32" fill="" />
          </clipPath>
        </defs>
      </svg>
    ),
    sub: "Defina prazos para expiração de senhas, garantindo trocas regulares para manter a segurança.",
    // topicos: [{ topic:"•	Reforce a troca regular de senhas para maior segurança."}],
  },

  {
    id: 5,
    title: "Bloqueio de protocolos",
    img:block,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="37"
        viewBox="0 0 32 37"
        fill="none"
      >
        <g clip-path="url(#clip0_9_197)">
          <path
            d="M10.2857 10.2857V13.7143H21.7143V10.2857C21.7143 7.12857 19.1571 4.57143 16 4.57143C12.8429 4.57143 10.2857 7.12857 10.2857 10.2857ZM5.71429 13.7143V10.2857C5.71429 4.60714 10.3214 0 16 0C21.6786 0 26.2857 4.60714 26.2857 10.2857V13.7143H27.4286C29.95 13.7143 32 15.7643 32 18.2857V32C32 34.5214 29.95 36.5714 27.4286 36.5714H4.57143C2.05 36.5714 0 34.5214 0 32V18.2857C0 15.7643 2.05 13.7143 4.57143 13.7143H5.71429Z"
            fill="#FF2E00"
          />
        </g>
        <defs>
          <clipPath id="clip0_9_197">
            <rect width="32" height="36.5714" fill="" />
          </clipPath>
        </defs>
      </svg>
    ),
    sub: "Minimize o risco bloqueando logins via protocolos IMAP, POP ou WEBMAIL não utilizados pelos clientes para reduzir a chance de acesso às contas por atacantes.",
    topicos: [
      // { topic:"•	Minimize o acesso às contas por protocolos menos seguros."},
    ],
  },
];
