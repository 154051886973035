import React from "react";

import "./style.css";
import Reveal from "../Reveal";
function Info() {
  return (
    <div className="info_wrapper">
      <div className="info_container">
        <Reveal
          component={
            <h1>
              Adote agora <strong>medidas de segurança </strong>
              <br />
              avançadas com o Mav Mail!
            </h1>
          }
        />
        <Reveal
          component={
            <p>
              Estamos aqui para ajudar durante todo o processo de implementação.
              Entre em contato para mais informações.
            </p>
          }
        />
        <Reveal
          component={
            <strong>
              Atenciosamente, <br />
              Equipe MAV Tecnologia
            </strong>
          }
        />
      </div>
    </div>
  );
}

export default Info;
