import "./App.css";
import Menu from "./components/Menu";
import Banner from "./components/Banner";
import Resources from "./components/Resources";
import Footer from "./components/Footer";
import Info from "./components/Info";


function App() {
  return (
    <div className="App">
      <Menu/>
      <Banner />
       <Resources/>
      <Info/>
      <Footer/>
    </div>
  );
}

export default App;
