import React, { useState } from "react";

import "./style.css";
import { data } from "../../data/resources";
import Reveal from "../Reveal";
function Resources() {
  const [ind, setInd] = useState(0);

  const AddIndex = (index) => {
    setInd(index);
  };
  return (
    <div className="resources_wrapper">
      <div className="resources_container">
        <Reveal
          component={
            <h1>
              Funcionalidades do MAV Mail que{" "}
              <strong>podem evitar os ataques</strong>
            </h1>
          }
          delay={2}
        />
        <div className="slide_container">
          <div className="slide_left">

          <div className="icons_container">
              {data?.map((i, index) => {
                return (
                  <Reveal
                    component={
                      <div
                        style={
                          index === ind
                            ? {
                                border: "5px solid #FF2E00",
                                path: "#fff!important",
                              }
                            : {
                                border: "1px solid #bbb",
                                path: "#FF2E00!important",
                              }
                        }
                        onClick={() => AddIndex(index)}
                        className="box_icon"
                        key={index}
                      >
                        {i.icon}
                      </div>
                    }
                  />
                );
              })}
            </div>

            <Reveal component={<h2>{data[ind]?.title}</h2>} />
            <Reveal component={<p>{data[ind]?.sub}</p>} />
            <Reveal
              component={
                <div className="topics">
                  {data[ind]?.topicos?.map((t, index) => {
                    return <p key={index}>{t.topic}</p>;
                  })}
                </div>
              }
            />

          </div>
          <div className="slide_right">
            <Reveal
              component={<img src={data[ind]?.img} alt="security"></img>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
